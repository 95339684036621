.home-container.photo-art.photo-art-display.writing-section.container {
  min-height: unset;
}

.photo-art-row.display-row.writing-section {
  /* border: solid 1px yellow; */
  height: calc(1281 / 2216 * 100vw - 500px);
  max-height: 600px;
}

.photo-art-row.categories-row.writing-section {
  margin-top: 50px;
}

.photo-art-main-image.writing-section {
  /* border: solid 1px white; */
  /* height: calc(100% + 250px); */
  height: calc(100% + 130px);
  /* height: 120%; */
  position: relative;
}

.writing-section-first-div {
  width: 100%;
  height: 100%;
  /* border: solid 1px green; */
  position: absolute;
  padding-top: 100px !important;
  /* bottom: 0; */
}

.writing-section-first-div img {
  width: 679px;
  height: 452px;
  max-width: unset !important;
  max-height: unset !important;
  position: absolute;
  bottom: 0;
}

.writing-section-first-div img.book-sub-image {
  width: 140px;
  left: calc(50% - 70px);
}

.writing-section-first-div .writings-text-div {
  /* border: solid 1px red; */
}

.writing-section-first-div .main-sub-title-div {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.writing-section-first-div .writings-text-div .main-title {
  /* position: absolute; */
  /* margin-top: max(150px, 5%); */
  margin-top: min(calc(1281 / 2216 * 100vw - 790px), 300px);
  font-size: 50px;
  text-align: center;
  text-transform: uppercase;
  opacity: 0.67;
  letter-spacing: 22.55px;
  font-family: "Montserrat", sans-serif;
  color: #919191;
}

.writing-section-first-div .writings-text-div .sub-title-category {
  text-align: left;
  font-size: 32px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0.64px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
}

.writing-category-icon-overlap {
  position: absolute;
  left: 30px;
  top: 20px;
}

.writing-title-div {
  font-family: "Montserrat", sans-serif;
  font-size: 26px;
  letter-spacing: 0.52px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
}
.writing-publish-date-div {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  letter-spacing: 0.4px;
  color: #dddddd;
  opacity: 1;
}
.writing-body-div {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #ffffff;
  opacity: 1;
  margin-top: 15px;
  /* border: solid 1px white; */
}

.home-container.photo-art.photo-art-display.writing-section .card {
  text-align: center;
  margin-bottom: 30px;
}

#mobile-photo-art-main-div.writings-section .card:hover,
.home-container.photo-art.photo-art-display.writing-section .card:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transform: scale(1.012);
  -ms-transform: scale(1.012);
  -webkit-transform: scale(1.012);
}

.home-container.photo-art.photo-art-display.writing-section .card-body {
  height: 80px;
}
.home-container.photo-art.photo-art-display.writing-section .card img {
  width: 100px;
  margin: 50px auto 10px auto;
}

.home-container.photo-art.photo-art-display.writing-section .card .text-muted {
  color: #6c757d !important;
  font-size: 20px;
  text-transform: capitalize;
  font-family: "Montserrat", sans-serif;
  color: #888 !important;
}

.home-container.photo-art.photo-art-display.writing-section
  .categories-title-div {
  font-size: 50px;
  text-transform: uppercase;
  opacity: 0.67;
  letter-spacing: 22px;
  font-family: "Montserrat", sans-serif;
  color: #919191;
  opacity: 1;
}
