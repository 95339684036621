@font-face {
  font-family: my-ink-free;
  src: url("../../../fonts/Inkfree.ttf");
}

.ef-menu-title {
  font-family: my-ink-free;
  /* font-weight: 600; */
  font-size: 28px;
  letter-spacing: 0.05em;
  /* margin-top: 10px; */
  display: inline-block;
  text-transform: capitalize;
}



.menu .select__field {
    font-family: my-ink-free;
    font-weight: 600;
    letter-spacing: 0.07em;
}

.menu {
    font-family: my-ink-free; 
}

.menu select {
  width: 320px !important;
}