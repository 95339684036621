#mobile-photo-art-main-div.writings-section .book-sub-image {
  position: absolute;
  top: calc(50% - 50px);
  width: 100px !important;

  width: fit-content;
  right: 0;
  left: 0;
  margin: auto;
}

#mobile-photo-art-main-div.writings-section .box-title.sub-title {
  font-size: 20px;
  margin-top: -20px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 50px;
}

#mobile-photo-art-main-div.writings-section
  .material-icons.go-back-to-category-icon {
  position: absolute;
  left: 5%;
  font-size: 90px;
}
