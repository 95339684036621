.home-body a.menu-item {
    font-family: my-ink-free;
    font-weight: 600;
    font-size: 18px;
    padding: 0 10px;
    width: fit-content;
}
.home-body a.menu-item.active-item {
    background-color: #ddd;
}

.bm-menu .menu-item.only-text {
    font-family: my-ink-free;
    /* padding: 0 10px; */
    width: fit-content;
    font-weight: 600;
    font-size: 18px;
}
.bm-menu .menu-item.only-text a {
    font-size: 18px;
}

a.menu-item span.email-at {
    font-family: 'Times New Roman', Times, serif;
}

.menu select { 
    background: black !important;

}