.mobile-menu-main-div {
  width: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
}
.bm-burger-button {
  position: absolute !important;
}
.bm-menu-wrap {
  width: 85% !important;
}
.bm-menu {
  background: #eee !important;
  font-size: 20px !important;
  width: 100%;
}
.bm-cross {
  background: #222 !important;
  width: 4px !important;
  height: 24px !important;
}

.home-body .bm-menu a {
  color: #555 !important;
  /* font-size: 20px !important; */
}

.menu-item-box,
.menu-item-box-1 {
  width: 100px;
  height: 100px;
  margin: 10px;
  margin-left: 40px;
  float: left;
  /* background-color: blue; */
  position: relative;
}

.menu-item-box-1 {
  /* width: 100px !important; */
  margin-top: 0;
  float: right !important;
}
.bm-burger-bars {
  background: #ddd !important;
  height: 10% !important;
}

.menu-item-box img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  margin-top: 3px;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  right: 16px !important;
  top: 16px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

.bm-menu .menu-item.only-text {
  font-size: 16px;
}
.bm-menu .menu-item {
  display: block;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
