.home-body {
  width: 100%;
  background-color: #000;
  color: #eee;
  min-height: 100vh;
}

.home-body a {
  color: #fff !important;
  /* font-size: 14px; */
  font-size: 20px;
}
.home-body a:hover {
  text-decoration: none;
}
.home-body a.active {
  color: #bc8c09 !important;
  cursor: default;
  font-size: 18px;
}

.home-container {
  /* border: solid 1px red; */
  min-height: 100vh;
}

.home-container .row {
  /* border: solid 1px #111; */
  padding: 30px 0;
}

/* Bootstrap 4 text input with search icon */

.has-search .form-control {
  /* padding-left: 2.375rem; */
  padding-right: 2.375rem;
  background: transparent !important;
  border: none;

  border-radius: 0 !important;
  color: #fff !important;
  width: 280px;
  margin-top: -8px;
}

.has-search .form-control,
.has-search .form-control:focus {
  border-bottom: solid 1px #707070;
}

.has-search .form-control-feedback {
  position: absolute;
  /* left: -20px; */
  /* top: 0; */
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #eee;
}

.right-search {
  top: -8px;
  left: 260px;
}

.photo-write-row {
  /* border: solid 2px #fff !important; */
}

/* .photo-write-row col- {
    border: solid 2px #fff !important;
} */

.home-outer-box {
  /* border: solid 2px #fff !important; */
}

.home-outer-box .home-inner-box {
  background-color: #ddd;
  position: relative;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
}

.home-outer-box .home-inner-box {
  /* not good  */
  width: 570px;
  height: 500px;
}

.home-outer-box .home-inner-box.photo-art {
  /* border: 1px solid blue; */
  overflow: hidden;
  /* position: relative; */
  /* float: left; */
  display: inline-block;
}

.home-inner-box.with-border {
  background-color: unset;
  border: solid 1px #353535;
}

.home-inner-box-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  font-size: 20px;
  color: white;
}

/* zoom */
.home-outer-box .home-inner-box.photo-art:hover .home-inner-box-content {
  transform: scale(1.1);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
}

.home-outer-box .home-inner-box.photo-art:not(:hover) .home-inner-box-content {
  transform: scale(1);
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  -moz-transition: transform 0.5s;
  -o-transition: transform 0.5s;
}

.home-inner-box.photo-art .home-inner-box-content::before {
  content: "";
  display: none;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(52, 73, 94, 0.3);
}

.home-outer-box .home-inner-box.photo-art:hover .home-inner-box-content:before {
  display: block;
}
/* zoom */

.home-inner-box-content.writing {
  /* padding: 40% 20px; */
  background-image: url("../../images/writings.jpeg");
  background-size: auto 100%;
  background-size: cover;
}

/* handle click writing dialogue  start */
.home-outer-box.photo-art-column-reset.writing-section:hover,
.same-edge-container.writing-image.writing-section:hover {
  cursor: pointer;
}

.coming-soon-div.show {
  display: unset;
}
.coming-soon-div {
  display: none;
  position: absolute;
  top: 150px;
  height: 200px;
  background-color: #eee;
  color: #171717;
  opacity: 0.8;
  width: 500px;
  left: 50px;
  font-size: 20px;
  padding: 40px 15px 15px 15px;
  text-align: center;
  z-index: 9999;
}

.coming-soon-div button,
.coming-soon-div button:hover,
.coming-soon-div button:focus {
  background-color: #444;
}

.same-edge-container.writing-image.writing-section {
  position: relative;
}
@media screen and (max-width: 1399px) {
  .coming-soon-div {
    top: 100px;
    width: 400px;
  }
}
@media screen and (max-width: 1199px) {
  .coming-soon-div {
    top: 100px;
    width: 330px;
  }
}

@media screen and (max-width: 999px) {
  .coming-soon-div {
    top: 12%;
    width: 90%;
    left: 5%;
  }
}
/* handle click writing dialogue end */

.home-inner-box-content.writing p {
  /* line-height: 1.5;
  display: inline-block;
  vertical-align: middle; */
}

#photo-art-image {
  /* background-image: url("http://192.168.29.152/cms-system/uploads/6062bd118088e7.85338894.jpg"); */
  background-position: 50% 50%;
  background-size: cover;
  /* background-size: auto 150%; */
  background-repeat: no-repeat;
  /* animation: fadein 5s; */
}

#photo-art-image.odd {
  animation: fadein-odd 1.5s ease-in-out, fadeout-odd 0.3s ease-in-out 4.7s 1;
}
@keyframes fadein-odd {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeout-odd {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.1;
  }
}
#photo-art-image.even {
  animation: fadein-even 1.5s ease-in-out, fadeout-even 0.3s ease-in-out 4.7s 1;
}
@keyframes fadein-even {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout-even {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.1;
  }
}

#photo-art-image.with-bio-profile-image {
  background-image: url("../../images/bio_profile_image.png");
  background-size: auto 100%;
}

/* #photo-art-image:hover {
  background-size: 150% ;
  background-size: auto 180%;
  animation: enlarger 1s;
}
@keyframes enlarger {
  from {
    background-size: auto 150%;
  }
  to {
    background-size: auto 180%;
  }
} */

#photo-art-image.active {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
    /* background-size: auto 130%; */
  }
  to {
    opacity: 1;
    /* background-size: auto 150%; */
  }
}

.galleries-title,
.home-outer-box .box-title,
#mobile-home-main-div .box-title,
#mobile-photo-art-main-div .box-title,
#mobile-gallery-main-div .box-title {
  width: fit-content;
  margin: 25px auto;
  color: #888;
  letter-spacing: 0.5em;
  word-spacing: 5px;
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 300;
  font-family: "Montserrat", sans-serif;
}

.box-title.home-page-box-title {
  width: 570px;
  text-align: center;
}

.home-outer-box.box-500 {
  width: 500px !important;
  max-width: 500px !important;
}

.photo-write-row.bio-row {
  padding-top: 0;
  margin-top: -40px;
}

.home-outer-box .box-title.bio-title {
  position: relative;
  font-family: "Lovers Quarrel", cursive;
  font-size: 70px;
  margin-top: -70px !important;
  z-index: 99999;
  letter-spacing: unset;
  color: #ddd;
  /* word-spacing: unset; */
  text-transform: lowercase;
}

.bio-content {
  text-align: center;
}

.copy-right {
  width: fit-content;
  margin: 0 auto;
  padding-bottom: 60px;
  font-size: 12px;
  color: #666;
  letter-spacing: 0.2em;
}

.foot-hr {
  margin: 30px 0;
  border-top: 1px solid #222;
}

.margin-bottom-70 {
  margin-bottom: 70px;
}
