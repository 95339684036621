.photo-art-row.categories-row .galleries-title,

.galleries-list .category-title,
#mobile-photo-art-main-div .category-title,
#mobile-photo-art-main-div .box-title,
#mobile-gallery-main-div .box-title {
  font-family: my-ink-free !important;
  /* text-transform: lowercase !important; */
  text-transform: capitalize !important;
}

.photo-art-small-text-div {
  opacity: 0.5;
}

.galleries-list .category-title,
#mobile-photo-art-main-div .category-title {
  /* font-weight: 600; */
  font-size: 18px;
  letter-spacing: 0.05em;
}

.menu-links.mobile-photo-art-link {
  font-family: my-ink-free !important;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.1em;
  margin-top: 0px;
}

.photo-art-bio-main-div.container p,
.bio-text.container p {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  text-shadow: 1px 1px 8px rgb(0 0 0 / 60%);
}

/* galleries-list */
.gallery-header,
.bottom-right,
.gallery-slide-show-title {
  font-family: my-ink-free !important;
}
.bottom-right {
  letter-spacing: 0.05em !important;
  font-weight: 600;
}
.gallery-slide-show-title {
  letter-spacing: 0.05em !important;
  font-weight: 600;
}

#mobile-gallery-main-div .box-title {
  font-weight: 600 !important;
  letter-spacing: 0.06em;
}

#mobile-gallery-main-div .gallery-item-div .gallery-item-title {
    font-family: my-ink-free;
    font-weight: 600;
}


.photo-art-display {
  background: #323a41 !important;
}

#mobile-photo-art-main-div .galleries-section {
  background: #323a41 !important;
}