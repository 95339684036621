#mobile-gallery-main-div .slideshow-link {
  font-family: my-ink-free;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.05em;
}

#mobile-gallery-main-div .slideshow-link span {
  cursor: pointer;
}


.gallery-header.limit-max-width-57 {
  max-width: 57%;
  margin: auto;
}

.box-title.long-title-center {
  text-align: center;
}

.react-photo-gallery--gallery .image-div .bottom-right {
  text-transform: unset;
  left: unset;
  right: 20px;

}