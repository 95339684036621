#mobile-photo-art-main-div.writings-section {
  font-family: "Montserrat", sans-serif;
}

#mobile-photo-art-main-div.writings-section .photo-art-main-image {
  position: relative;
}
#mobile-photo-art-main-div.writings-section .photo-art-main-image .main-title {
  font-size: 80px;
  color: rgba(255, 255, 255, 0.3);
  letter-spacing: 0.2em;
  font-weight: 200;
  text-transform: uppercase;

  /* border: solid 1px red; */

  position: absolute;
  bottom: 10%;

  width: fit-content;
  right: 0;
  left: 0;
  margin: auto;
}

#mobile-photo-art-main-div.writings-section .writings-list-row {
  margin: 10px 50px;
  text-align: left;
}

#mobile-photo-art-main-div.writings-section .writing-title {
  font-size: 26px;
  text-transform: uppercase;
  color: #fff;
}

#mobile-photo-art-main-div.writings-section .writing-date {
  font-size: 24px;
}

#mobile-photo-art-main-div.writings-section .writing-image img {
  width: 30px;
}

#mobile-photo-art-main-div.writings-section .writing-body {
  font-size: 16px;
}

#mobile-photo-art-main-div.writings-section .box-title {
  text-align: left;
  font-size: 30px;
}

#mobile-photo-art-main-div.writings-section .card {
  padding-top: 40px;
  margin-bottom: 50px;
}

#mobile-photo-art-main-div.writings-section .writing-category-list-row {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
