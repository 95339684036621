.signature-image {
  padding-right: 50px;
  position: absolute;
  bottom: 45%;
  /* transform: rotate(-2deg); */
}

.signature-text {
  font-size: 28px;
  font-family: my-ink-free;
  /* font-weight: 600; */
  position: absolute;
  bottom: 10%;
  padding-left: 10px;
  letter-spacing: 0.06em;
}

img.profile-image {
  padding-left: 100px;
}

.display-images-div-row.row {
  margin-left: 0;
  margin-right: 0;
}

.display-images-background {
  background-image: url("../../../images/Frame-Left-BottomLonger.png");
  background-size: contain;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: left;
  padding: 90px;
  padding-left: 90px !important;
}

.display-images-background .home-outer-box .home-inner-box {
  width: 700px;
}

.my-photography-text {
  font-family: my-ink-free;
  /* font-weight: 600; */
  font-size: 28px;
  position: absolute;
  bottom: 48%;
  text-align: center;
  width: 100%;
  letter-spacing: 0.06em;
}

.about-me-title {
  font-size: 28px;
  font-family: my-ink-free;
  text-transform: capitalize;
  /* font-weight: 600; */
  margin-bottom: 30px;
}
.about-me-text p {
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  /* font: normal normal normal 16px/30px Montserrat; */
  letter-spacing: 0.32px;
}

.home-body a.active {
  font-size: 20px;
}

@media screen and (max-width: 1399px) {
  .display-images-background .home-outer-box .home-inner-box {
    width: 555px !important;
    height: 525px !important;
  }
}

@media screen and (max-width: 1199px) {
  .signature-with-profile-image-col {
    padding: 0 !important;
  }

  .signature-text {
    font-size: 24px;
  }
  img.profile-image {
    padding-left: 40px;
  }
  .display-images-background {
    padding: 60px;
    padding-left: 60px !important;
  }
  .my-photography-text {
    font-size: 28px;
    padding-left: 20px;
  }
  .display-images-background .home-outer-box .home-inner-box {
    width: 495px !important;
    height: 425px !important;
  }
}

/* Mobile start  */
.mobile-home-profile-image-div {
  width: 80%;
  margin: auto;
  position: relative;
}

.signature-mobile-image {
  position: absolute;
  bottom: 5%;
  left: 45%;
  /* -webkit-transform: rotate(-8deg);
  -moz-transform: rotate(-8deg);
  -ms-transform: rotate(-8deg);
  -o-transform: rotate(-8deg);
  transform: rotate(-8deg); */
}

.signature-mobile-text {
  font-family: my-ink-free;
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0.06em;
  /* font-family: "Times New Roman", Times, serif; */
  width: fit-content;
  margin: -9px auto 50px auto;
  color: #888;
}

.mobile-home-about-me {
  width: 80%;
  margin: auto;
}

#mobile-home-main-div .box-title { 
  font-family: my-ink-free;
  text-transform: capitalize;
  letter-spacing: 0.06em;
  font-size: 26px;
  font-weight: 600;
}

@media screen and (max-width: 479px) {
  .signature-mobile-text {
    margin-top: 0;
    font-size: 17px;
    letter-spacing: 0.01rem;
  }
  #mobile-home-main-div .box-title {
    font-size: 18px;
  }
  .about-me-title {
    font-size: 30px;
    margin-bottom: 15px;
  }
  .about-me-text p {
    font-size: 16px;
    letter-spacing: 0.05em;
  }
}

#photo-art-image {
  background-size: 190%;
}